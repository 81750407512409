/* eslint-disable max-lines */
import { Component, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { QueryType, UserRole } from "../../models";
import { MultiSelect, Option } from "react-multi-select-component";
import paginationFactory from "react-bootstrap-table2-paginator";
import { format, parseISO, subDays } from "date-fns";
import { UTCDate } from "@date-fns/utc";
import { useFeatureFlags } from "../../hooks/useFeatureFlags";

import {
  Badge,
  Button,
  Col,
  Card,
  CardBody,
  Container,
  Form,
  Input,
  ListGroup,
  ListGroupItem,
  PopoverBody,
  Row,
  UncontrolledCollapse,
  UncontrolledPopover,
} from "reactstrap";
import { useUserInfo } from "../../hooks/useUserInfo";
import Loader from "../../components/Loader";
import { useMutation } from "@tanstack/react-query";
import { postQueryLogs } from "./api/postQueryLogs";
import { ButtonType, CDataButton } from "../../components/buttons/CDataButton";
import { useLocation } from "react-router-dom";
import { getDownloadQueryLogs } from "./api/downloadQueryLogs";
import { CDataDateRangePicker } from "../../components/datepicker/CDataDateRangePicker";
import { Range } from "react-date-range";
import { convertLogDateToUTC } from "./logsFunctions";
import { trimEnd } from "lodash";
import { getUserList } from "../users/UserApiCalls";
import { useAPI } from "../../components/useAPI";
import { isFeatureAllowed } from "../../utility/SubscriptionAddonsFactory";
import { FeatureId } from "../../models/Features/FeatureId";
import { useAppSelector } from "../../redux/hooks";
import { CDataLogsDateRangePicker } from "../../components/datepicker/CDataLogsDateRangePicker";
import { useDateRange } from "../../hooks/useDateRange";
import classnames from "classnames";

interface IStatusProps {
  enabled: boolean;
}

class Status extends Component<IStatusProps> {
  render() {
    const name = this.props.enabled ? "Success" : "Failed";
    const color = this.props.enabled ? "success" : "danger";

    return <Badge color={color}>{name}</Badge>;
  }
}

interface ILogTableState {
  connection?: string;
  timePeriod?: number;
  user?: string;
}

const LogTable = () => {
  const {
    myRef,
    dateRange,
    setDateRange,
    anchorEl,
    setAnchorEl,
    dateRangeOptions,
    selectedDateRange,
    handleDateRangeSelectChange,
    handleItemClick,
    clearDateRange,
    clearDateRangeSelection,
  } = useDateRange();

  const api = useAPI();
  const subscription = useAppSelector((state) => state.subscription);
  const isCachingEnabled = isFeatureAllowed(
    subscription?.limits?.availableFeatureIds ?? [],
    FeatureId.Caching,
  );
  const [filterOptionsOpen, setFilterOptionsOpen] = useState<boolean>(false);
  const [selectedQueryTypes, setSelectedQueryTypes] = useState<any[]>([]);
  const [queryStatusType, setQueryStatusType] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [userEmails, setUserEmails] = useState<any[]>([]);
  const cacheWorkerOption = { label: "CacheWorker", value: "CacheWorker" };

  const emailOptions = [
    ...(isCachingEnabled ? [cacheWorkerOption] : []), // Add the cacheWorkerOption option only if isCachingEnabled is true
    ...userEmails.map((email) => ({
      label: email,
      value: email,
    })),
  ].sort((a, b) => a.label.localeCompare(b.label));

  const [selectedEmail, setSelectedEmail] = useState<Option | null>(null);

  const handleEmailSelectChange = (selected: Option[]) => {
    setSelectedEmail(
      selected.length > 0 ? selected[selected.length - 1] : null,
    );
  };

  const userInfo = useUserInfo();
  const isAdminUser = userInfo.Self.role === UserRole.Admin;
  const isQueryUser = userInfo.Self.role === UserRole.Query;
  const isOEM = userInfo.Self.role === UserRole.ServiceUser;

  const [querySearchInput, setQuerySearchInput] = useState<string>("");
  const [inputType, setInputType] = useState<string>("queryLike");
  const GUID_REGEX =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

  const handleInputChange = (value: string) => {
    setQuerySearchInput(value);
    setInputType(GUID_REGEX.test(value) ? "queryId" : "queryLike");
  };

  // Query to load the logs
  const { data, mutateAsync } = useMutation({
    mutationKey: ["log/query/list"],
    mutationFn: postQueryLogs,
    meta: {
      errorMessage:
        "Failed to get list of query logs due to the following error:",
    },
  });

  // Query to download the logs file
  const { mutateAsync: downloadLogsAsync } = useMutation({
    mutationKey: ["/account/queries/{queryId}/queryLogs"],
    mutationFn: getDownloadQueryLogs,
    meta: {
      errorMessage: "Cannot download logs due to the following error:",
    },
  });

  const queryLogs = data?.queries ?? [];
  const queryLogsIncomplete = data?.incomplete ?? false;
  const flags = useFeatureFlags().getFlags(["audit_log_filters"]);
  const [isDirty, setIsDirty] = useState(false);
  const queryStatusOptions = [
    { label: "Success", value: 2 },
    { label: "Failed", value: 1 },
  ];
  const [selectedQueryStatus, setSelectedQueryStatus] = useState<any[]>([]);
  const filterButtonDisabled =
    (dateRange === null || dateRange.startDate === undefined) &&
    selectedQueryTypes.length === 0 &&
    selectedQueryStatus.length === 0 &&
    selectedEmail === null &&
    querySearchInput === "";

  const handleSelectChange = (selectedOptions: any) => {
    setSelectedQueryStatus(selectedOptions);

    if (selectedOptions.length === 0 || selectedOptions.length === 2) {
      toggleQueryStatusType(0);
    } else {
      selectedOptions.forEach((option: any) => {
        toggleQueryStatusType(option.value);
      });
    }
  };

  const location = useLocation();
  const state = location.state as ILogTableState;

  // Sets the default state for various components when the Logs page opens.
  useEffect(() => {
    let filterParameters = null;

    if (!isAdminUser) {
      filterParameters = { credentialLike: userInfo.Self.email };
    }

    if (state && isAdminUser) {
      if (state.connection) {
        filterParameters = {
          ...filterParameters,
          ConnectionNames: [state.connection],
        };
      }
      if (state.user) {
        filterParameters = {
          ...filterParameters,
          Credential: [state.user],
        };
      }
    }

    if (state && state.timePeriod) {
      // Fools the selector into thinking the UTC date is the local time zone date, which fixes an issue with the time displaying incorrectly on load.
      const today = parseISO(trimEnd(new Date().toISOString(), "Z"));
      const range: Range = {
        startDate: subDays(today, state.timePeriod),
        endDate: today,
        key: "selection",
      };
      setDateRange(range);

      // Convert the range to UTC for the on-load query to ensure query works properly
      const utcConvertedRange = {
        startTime: convertLogDateToUTC(range.startDate!),
        endTime: convertLogDateToUTC(range.endDate!),
      };
      filterParameters = { ...filterParameters, ...utcConvertedRange };
    }

    const getUsersOnLoad = async () => {
      const payload = await getUserList(api.callAPI);
      if (payload && Array.isArray(payload.users)) {
        // Extract emails
        setUserEmails(payload.users.map((user) => user.email));
      }
    };

    async function componentDidMount(filterParameters: any) {
      // Query users cannot access audit logs, but it's good to be consistent anyway.
      await mutateAsync(filterParameters);
      setLoading(false);
    }

    const initialize = async () => {
      if (!isQueryUser) {
        await getUsersOnLoad(); // Fetch the user list only if not Query User
      }
      await componentDidMount(filterParameters); // Handle filter parameters
    };

    initialize();
  }, []); // eslint-disable-line

  function toggleFilterOptions() {
    setFilterOptionsOpen(!filterOptionsOpen);
  }

  async function submitQueryFilters(event: any) {
    event.preventDefault();
    const data: any = {};

    for (const item of event.target) {
      if (item.value) {
        data[item.name] = item.value;
      }
    }

    const startDate = dateRange?.startDate;
    const endDate = dateRange?.endDate;

    if (startDate) {
      data["startTime"] = convertLogDateToUTC(startDate);
    }

    if (endDate && !isNaN(endDate.getTime())) {
      data["endTime"] = convertLogDateToUTC(endDate);
    }

    if (selectedQueryTypes.length !== 0) {
      data["queryType"] = selectedQueryTypes.map((queryType) => {
        return queryType.value;
      });
    }

    if (queryStatusType === 1) {
      data["failed"] = true;
    } else if (queryStatusType === 2) {
      data["failed"] = false;
    }

    if (data["queryId"]) {
      data["queryId"] = data["queryId"].split(",");
    }

    if (selectedEmail) {
      data["CredentialLike"] = selectedEmail.value;
    }

    // Update data based on inputType of querySearchInput
    if (querySearchInput) {
      data[inputType] =
        inputType === "queryId" ? [querySearchInput] : querySearchInput;
    }

    await mutateAsync(data);
    setIsDirty(true);
  }

  const queryLogColumns = [
    {
      dataField: "timestamp",
      text: "Timestamp",
      sort: false,
      headerStyle: {
        width: "23%",
      },
    },
    {
      dataField: "queryType",
      text: "Query Type",
      sort: false,
      headerStyle: {
        width: "12%",
      },
    },
    {
      dataField: "credential",
      text: "User",
      sort: false,
      headerStyle: {
        width: "15%",
      },
      hidden: isOEM,
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
      headerStyle: {
        width: "10%",
        align: "center",
      },
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "query",
      text: "Query Text",
      sort: false,
      headerStyle: {
        width: isOEM ? "55%" : "40%",
      },
    },
  ];

  const queryLogData = queryLogs.map((queryLog: any) => {
    return {
      queryId: queryLog.queryId,
      timestamp: `${format(new UTCDate(queryLog.timestamp), "yyyy-MM-dd HH:mm:ss")} UTC`,
      queryType: QueryType[queryLog.queryType]
        ? QueryType[queryLog.queryType]
            .match(/[A-Z][a-z]+|[0-9]+|O[A-Z][a-z]+/g)
            ?.join(" ") ?? ""
        : "",
      credential: queryLog.credential ? queryLog.credential : "Admin Portal",
      query: queryLog.query,
      status: <Status enabled={!queryLog.errorCode} />,
      errorCode: queryLog.errorCode,
      errorMessage: queryLog.errorMessage,
      duration: queryLog.duration + " ms",
      rowsInput: queryLog.rowsInput,
      rowsAffected: queryLog.rowsAffected,
      rowsReturned: queryLog.rowsReturned,
      logFileKey: queryLog.logFileKey,
    };
  });

  const expandQueryRow = {
    className: "query-log-expanded-row",
    renderer: (row: any) => (
      <div className="mt-2 mb-n2">
        <Row>
          <Col>
            <p className="log-table-expanded-detail">
              <b className="me-2">Query Id</b>
              <pre className="linebreak-wrap linebreak-style">
                {row.queryId}
              </pre>
            </p>
          </Col>
          <Col>
            {!isOEM ? (
              <p className="log-table-expanded-detail">
                <b className="me-2">User</b>
                <pre className="linebreak-wrap linebreak-style">
                  {row.credential}
                </pre>
              </p>
            ) : (
              <></>
            )}
          </Col>
          <Col>
            <div className="log-button">
              <Button
                color="secondary"
                size="sm"
                style={{
                  cursor: "pointer",
                  display:
                    row.logFileKey &&
                    ((isOEM && row.logVerbosity < 3) || !isOEM)
                      ? "initial"
                      : "none",
                }}
                onClick={() => {
                  downloadLogsAsync({ queryId: row.queryId });
                }}
              >
                <i className="fa fa-file-download align-middle me-2 no-pointer-events" />
                Logs
              </Button>
            </div>
          </Col>
        </Row>
        <div>
          <b className="me-2">Query Text</b>
          <pre className="linebreak-wrap linebreak-style" id={row.query}>
            {row.query}
          </pre>
        </div>
        <div className={row.errorCode ? "log-table-error-detail" : ""}>
          {row.errorCode ? (
            <p className="log-table-expanded-detail">
              <b className="me-2">Error Code</b>
              <pre className="linebreak-wrap linebreak-style">
                {row.errorCode}
              </pre>
            </p>
          ) : null}
          {row.errorMessage ? (
            <p className="log-table-expanded-detail">
              <b className="me-2">Error Message</b>
              <pre className="linebreak-wrap linebreak-style">
                {row.errorMessage}
              </pre>
            </p>
          ) : null}
        </div>
        <Row>
          <Col>
            <p className="log-table-expanded-detail">
              <b className="me-2">Duration</b>
              <pre className="linebreak-wrap linebreak-style">
                {row.duration}
              </pre>
            </p>
          </Col>
          <Col>
            <p className="log-table-expanded-detail">
              <b className="me-2">Rows Input</b>
              <pre className="linebreak-wrap linebreak-style">
                {row.rowsInput}
              </pre>
            </p>
          </Col>
          <Col>
            <p className="log-table-expanded-detail">
              <b className="me-2">Rows Affected</b>
              <pre className="linebreak-wrap linebreak-style">
                {row.rowsAffected}
              </pre>
            </p>
          </Col>
          <Col>
            <p className="log-table-expanded-detail">
              <b className="me-2">Rows Returned</b>
              <pre className="linebreak-wrap linebreak-style">
                {row.rowsReturned}
              </pre>
            </p>
          </Col>
        </Row>
      </div>
    ),
    showExpandColumn: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }: any) =>
      isAnyExpands ? (
        <i className="fa-regular fa-circle-minus cursor-pointer"></i>
      ) : (
        <i className="fa-regular fa-circle-plus cursor-pointer"></i>
      ),
    expandColumnRenderer: ({ expanded }: any) =>
      expanded ? (
        <i className="fa-regular fa-circle-minus cursor-pointer"></i>
      ) : (
        <i className="fa-regular fa-circle-plus cursor-pointer"></i>
      ),
  };
  const queryTypeOptions = convertToKeyValuePair(QueryType);

  function convertToKeyValuePair(
    enumList: typeof QueryType,
  ): { value: string; label: string }[] {
    const options: { value: string; label: string }[] = [];

    for (const key in enumList) {
      if (isNaN(Number(key))) {
        const value = enumList[key];
        options.push({ value: value, label: key });
      }
    }

    return options;
  }

  function toggleQueryStatusType(type: number) {
    if (queryStatusType !== type) {
      setQueryStatusType(type);
    }
  }

  function resetFilters() {
    clearDateRange();
    clearDateRangeSelection();
    setSelectedQueryTypes([]);
    setSelectedQueryStatus([]);
    toggleQueryStatusType(0);
    setSelectedEmail(null);
    setQuerySearchInput("");
  }

  const ItemRenderer = ({
    option,
    onClick,
    disabled,
  }: {
    option: Option;
    onClick: () => void;
    disabled: boolean;
  }) => (
    <div
      onClick={() => handleItemClick(option, onClick, disabled)}
      className={`multi-select-item ${disabled ? "disabled" : ""}`}
    >
      {option.label}
    </div>
  );

  const renderClearButton = (
    selected: Option[],
    clearFunction: () => void,
    label: string,
  ) => {
    if (selected.length === 0) return null;

    return (
      <CDataButton
        className="quick-clear-button"
        buttonType={ButtonType.Borderless}
        aria-label={`Clear ${label}`}
        onClick={(event) => {
          clearFunction();
          event.stopPropagation();
        }}
      >
        <i className="fa fa-solid fa-circle-xmark" />
      </CDataButton>
    );
  };

  const renderBadge = (selected: Option[]) => {
    if (selected.length === 0) return null;

    return (
      <Badge className="multi-drop-down-badge badge" color="">
        {selected.length}
      </Badge>
    );
  };

  const isDateRangeSelected = dateRange?.startDate ? 1 : 0;

  function customValueRenderer(
    selected: Option[],
    _options: Option[],
    label: string,
    clearFunction: () => void,
  ) {
    if (selected.length === 0 && label !== "Date Range") {
      return <span className="multi-drop-down-placeholder">{label}</span>;
    }

    if (label === "Date Range" && isDateRangeSelected === 0) {
      return <span className="multi-drop-down-placeholder">{label}</span>;
    }

    return (
      <>
        <span>{label}</span>
        <div className="multi-drop-down-button">
          {renderBadge(selected)}
          {renderClearButton(selected, clearFunction, label)}
        </div>
      </>
    );
  }

  const clearQueryTypes = () => {
    setSelectedQueryTypes([]);
  };

  const clearQueryStatus = () => {
    toggleQueryStatusType(0);
    setSelectedQueryStatus([]);
  };

  const clearUserEmail = () => {
    setSelectedEmail(null);
  };

  const clearQuerySearchInput = () => {
    setQuerySearchInput("");
  };

  if (loading) {
    return (
      <Container fluid className="p-0">
        <Loader />
      </Container>
    );
  }

  return (
    <>
      {!flags.audit_log_filters.enabled ? (
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row className="w-100">
                  <Col className="me-n2 expand-filter-box">
                    <a
                      id="collapseFilterLog"
                      href="#collapseFilterLog"
                      className="text-decoration-none"
                      onClick={toggleFilterOptions}
                    >
                      <h4 className="card-title mb-0">
                        {filterOptionsOpen === false ? (
                          <i className="fa-regular fa-circle-plus"></i>
                        ) : (
                          <i className="fa-regular fa-circle-minus"></i>
                        )}
                      </h4>
                    </a>
                  </Col>
                  <CDataDateRangePicker
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                  />
                  <Col className="filter-logs-button">
                    <Button
                      id="filterButton"
                      form="queryFilterForm"
                      color="primary"
                      type="submit"
                      data-testid="button-logs-search"
                    >
                      <i className="fa fa-search align-middle me-2 no-pointer-event" />{" "}
                      Search
                    </Button>
                  </Col>
                </Row>
                <UncontrolledCollapse
                  toggler="#collapseFilterLog"
                  defaultOpen={false}
                  className="mt-4"
                >
                  <Form id="queryFilterForm" onSubmit={submitQueryFilters}>
                    <Row className="mb-4">
                      <Col className="flexbox-container">
                        <label>Query Type(s):</label>
                        <MultiSelect
                          options={queryTypeOptions}
                          value={selectedQueryTypes}
                          onChange={setSelectedQueryTypes}
                          labelledBy="Select"
                        />
                      </Col>
                      <Col>
                        <div>
                          <ListGroup
                            horizontal
                            className="connected-button-group"
                          >
                            <ListGroupItem
                              action
                              type="button"
                              onClick={() => toggleQueryStatusType(0)}
                              active={queryStatusType === 0}
                              className="text-center"
                              data-testid="button-all-queries"
                            >
                              All Queries
                            </ListGroupItem>
                            <ListGroupItem
                              action
                              type="button"
                              onClick={() => toggleQueryStatusType(1)}
                              active={queryStatusType === 1}
                              className="text-center"
                              data-testid="button-failed"
                            >
                              Failed
                            </ListGroupItem>
                            <ListGroupItem
                              action
                              type="button"
                              onClick={() => toggleQueryStatusType(2)}
                              active={queryStatusType === 2}
                              className="text-center"
                              data-testid="button-successful"
                            >
                              Successful
                            </ListGroupItem>
                          </ListGroup>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      {!isOEM ? (
                        <Col className="flexbox-container">
                          <label>Username:</label>
                          <Input
                            readOnly={!isAdminUser}
                            disabled={!isAdminUser}
                            defaultValue={
                              isAdminUser ? "" : userInfo.Self.email
                            }
                            type="text"
                            id="credentialLike"
                            name="credentialLike"
                          ></Input>
                        </Col>
                      ) : (
                        <></>
                      )}
                      <Col className="flexbox-container">
                        <label>Query Id(s):</label>
                        <Input type="text" id="queryId" name="queryId"></Input>
                        <UncontrolledPopover
                          placement="top"
                          target="queryId"
                          trigger="focus"
                        >
                          <PopoverBody>
                            GUID values; comma-seperated
                          </PopoverBody>
                        </UncontrolledPopover>
                      </Col>
                    </Row>
                    <Row className="mb-1">
                      <Col className="flexbox-container">
                        <label>Query Text:</label>
                        <Input
                          type="text"
                          id="queryLike"
                          name="queryLike"
                        ></Input>
                      </Col>
                    </Row>
                  </Form>
                </UncontrolledCollapse>
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        <Row className="pages-logs-Table">
          <Form id="queryFilterForm" onSubmit={submitQueryFilters}>
            <Row className="mb-1">
              <Col className="flexbox-container">
                <div className="input-with-icon">
                  <i className="fa fa-search search-icon" />
                  <Input
                    type="text"
                    id={inputType}
                    name={inputType}
                    placeholder="Search by Query Text or Query Id"
                    value={querySearchInput}
                    onChange={(event) => handleInputChange(event.target.value)}
                  />
                  {querySearchInput && (
                    <div className="cancel-button">
                      <CDataButton
                        className="quick-clear-button"
                        buttonType={ButtonType.Borderless}
                        aria-label="Clear search"
                        onClick={(event) => {
                          clearQuerySearchInput();
                          event.stopPropagation();
                        }}
                      >
                        <i className="fa fa-solid fa-circle-xmark" />
                      </CDataButton>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col className="def">
                <span ref={myRef} className="align-date-range">
                  <MultiSelect
                    onMenuToggle={() => setAnchorEl(myRef?.current)}
                    options={dateRangeOptions}
                    value={selectedDateRange ? [selectedDateRange] : []}
                    onChange={handleDateRangeSelectChange}
                    labelledBy="Date Range"
                    hasSelectAll={false}
                    disableSearch
                    closeOnChangedValue={true}
                    valueRenderer={(selected, options) =>
                      customValueRenderer(
                        selected,
                        options,
                        "Date Range",
                        clearDateRange,
                      )
                    }
                    ClearSelectedIcon
                    ItemRenderer={ItemRenderer}
                  />
                  {selectedDateRange?.label === "Custom" && (
                    <CDataLogsDateRangePicker
                      anchorEl={anchorEl}
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                    />
                  )}
                </span>
              </Col>
              <Col className="def flexbox-container dropdown-container">
                <MultiSelect
                  options={queryTypeOptions}
                  value={selectedQueryTypes}
                  onChange={setSelectedQueryTypes}
                  labelledBy="Select Query Type"
                  valueRenderer={(selected, options) =>
                    customValueRenderer(
                      selected,
                      options,
                      "Query Type",
                      clearQueryTypes,
                    )
                  }
                  ClearSelectedIcon
                />
              </Col>
              {!isQueryUser && (
                <Col className="def flexbox-container dropdown-container x">
                  <MultiSelect
                    options={emailOptions}
                    value={selectedEmail ? [selectedEmail] : []}
                    onChange={handleEmailSelectChange}
                    labelledBy="Select User"
                    hasSelectAll={false}
                    disableSearch
                    closeOnChangedValue={true}
                    valueRenderer={(selected, options) =>
                      customValueRenderer(
                        selected,
                        options,
                        "User",
                        clearUserEmail,
                      )
                    }
                    ClearSelectedIcon
                    ItemRenderer={({
                      option,
                      onClick,
                      disabled,
                    }: {
                      option: Option;
                      onClick: () => void;
                      disabled: boolean;
                    }) => (
                      <div
                        onClick={() => {
                          if (!disabled) {
                            onClick();
                          }
                        }}
                        className={`multi-select-item ${disabled ? "disabled" : ""}`}
                      >
                        {option.label}
                      </div>
                    )}
                  />
                </Col>
              )}
              <Col className="def flexbox-container dropdown-container">
                <MultiSelect
                  options={queryStatusOptions}
                  value={selectedQueryStatus}
                  onChange={handleSelectChange}
                  labelledBy="Select Status"
                  valueRenderer={(selected, options) =>
                    customValueRenderer(
                      selected,
                      options,
                      "Status",
                      clearQueryStatus,
                    )
                  }
                  hasSelectAll={false}
                  ClearSelectedIcon
                  disableSearch
                />
              </Col>
              <Col className="custom-col-button">
                <Button
                  id="filterButton"
                  data-testid="button-logs-search"
                  form="queryFilterForm"
                  color="primary"
                  type="submit"
                  disabled={!isDirty && filterButtonDisabled}
                >
                  <i className="fa fa-check align-middle me-2 no-pointer-event" />
                  Apply
                </Button>
                <Button
                  id="filterButton"
                  form="queryFilterForm"
                  className="tertiary-button btn-secondary clear-all-button"
                  onClick={() => resetFilters()}
                  aria-label="delete license"
                  disabled={filterButtonDisabled}
                >
                  <i className="fa-regular fa-xmark" />
                  &nbsp;&nbsp;Clear all
                </Button>
              </Col>
            </Row>
          </Form>
        </Row>
      )}
      <Row>
        <Col>
          <Card>
            <CardBody>
              <h5 className="card-title">Query Log</h5>
              <BootstrapTable
                bootstrap4
                id="queryLogTable"
                classes="log-table query-table"
                bordered={false}
                keyField="queryId"
                columns={queryLogColumns}
                data={queryLogData}
                expandRow={expandQueryRow}
                pagination={paginationFactory({
                  sizePerPage: 10,
                  sizePerPageList: [5, 10, 25, 50],
                  page: 1,
                  paginationTotalRenderer() {
                    return (
                      <span
                        className={classnames("p-1 text-muted", {
                          "log-table-paginationTotalRenderer":
                            !queryLogsIncomplete,
                        })}
                      >
                        Only the first 500 results are returned. Use our filters
                        to refine your search to access additional results.
                      </span>
                    );
                  },
                  showTotal: true,
                })}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default LogTable;
