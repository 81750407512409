/* eslint-disable max-lines */
import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  Button,
  Col,
  Card,
  CardBody,
  Container,
  Form,
  Row,
  UncontrolledCollapse,
  Badge,
} from "reactstrap";

import Loader from "../../components/Loader";
import { RequestType } from "../../components/withAPI";
import { AuditEventType, EntityType, QueryType, UserRole } from "../../models";
import { useAPI } from "../../components/useAPI";
import { useUserInfo } from "../../hooks/useUserInfo";
import { MultiSelect, Option } from "react-multi-select-component";
import { IAuditEventRecord } from "../../models/Auditing/IAuditEventRecord";
import { IAuditEventSearchCriteria } from "../../models/Auditing/IAuditEventSearchCriteria";
import { formatUTCDateTime } from "../../utility/FormatUTCDateTime";
import { CDataDateRangePicker } from "../../components/datepicker/CDataDateRangePicker";
import { convertLogDateToUTC } from "./logsFunctions";

import { CDataButton, ButtonType } from "../../components/buttons/CDataButton";
import { useFeatureFlags } from "../../hooks/useFeatureFlags";
import { CDataLogsDateRangePicker } from "../../components/datepicker/CDataLogsDateRangePicker";
import { useDateRange } from "../../hooks/useDateRange";
import { getUserList } from "../users/UserApiCalls";
import { isFeatureAllowed } from "../../utility/SubscriptionAddonsFactory";
import { FeatureId } from "../../models/Features/FeatureId";
import { useAppSelector } from "../../redux/hooks";
const AuditTable = () => {
  const {
    myRef,
    dateRange,
    setDateRange,
    anchorEl,
    setAnchorEl,
    dateRangeOptions,
    selectedDateRange,
    handleDateRangeSelectChange,
    handleItemClick,
    clearDateRange,
    clearDateRangeSelection,
  } = useDateRange();

  const subscription = useAppSelector((state) => state.subscription);
  const isCachingEnabled = isFeatureAllowed(
    subscription?.limits?.availableFeatureIds ?? [],
    FeatureId.Caching,
  );

  const userInfo = useUserInfo();
  const isAdminUser =
    userInfo.Self.role === UserRole.Admin ||
    userInfo.Self.role === UserRole.ServiceUser;
  const isOEM = userInfo.Self.role === UserRole.ServiceUser;
  const [auditLogs, setAuditLogs] = useState<IAuditEventRecord[]>([]);
  const [auditLogsIncomplete, setAuditLogsIncomplete] =
    useState<boolean>(false);
  const [filterOptionsOpen, setFilterOptionsOpen] = useState<boolean>(false);
  const [selectedAuditTypes, setSelectedAuditTypes] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const api = useAPI();
  const auditTypeOptions = convertToKeyValuePair(AuditEventType);
  const flags = useFeatureFlags().getFlags(["audit_log_filters"]);
  const [isDirty, setIsDirty] = useState(false);

  const [userEmails, setUserEmails] = useState<any[]>([]);
  const cacheWorkerOption = { label: "CacheWorker", value: "CacheWorker" };
  const emailOptions = [
    ...(isCachingEnabled ? [cacheWorkerOption] : []), // Add the cacheWorkerOption option only if isCachingEnabled is true
    ...userEmails.map((email) => ({
      label: email,
      value: email,
    })),
  ].sort((a, b) => a.label.localeCompare(b.label));
  const [selectedEmail, setSelectedEmail] = useState<Option | null>(null);
  const handleEmailSelectChange = (selected: Option[]) => {
    setSelectedEmail(
      selected.length > 0 ? selected[selected.length - 1] : null,
    );
  };

  const isQueryUser = userInfo.Self.role === UserRole.Query;

  const filterButtonDisabled =
    (dateRange === null || dateRange.startDate === undefined) &&
    selectedAuditTypes.length === 0 &&
    selectedEmail === null;

  // Sets the default state for various components when the Logs page opens.
  useEffect(() => {
    /* Sets the default username (credentialLike) to the appropriate value when first loading the Logs page. For admin users, this is blank, which shows
     * all records as intended. For query users, this is their username, which ensures that query users only see their own logs when they load the page. */
    const filterParameters = isAdminUser
      ? null
      : { credentialLike: userInfo.Self.email };

    async function componentDidMount(filterParameters: any) {
      // Query users cannot access audit logs, but it's good to be consistent anyway.
      await getAuditLogs(filterParameters);
      setLoading(false);
    }

    const getUsersOnLoad = async () => {
      const response = await getUserList(api.callAPI);
      if (response && Array.isArray(response.users)) {
        // Extract emails
        setUserEmails(response.users.map((user) => user.email));
      }
    };

    const initialize = async () => {
      if (!isQueryUser) {
        await getUsersOnLoad(); // Fetch the user list only if not Query User
      }
      await componentDidMount(filterParameters); // Handle filter parameters
    };

    initialize();
  }, []); // eslint-disable-line

  async function submitAuditFilters(event: any) {
    event.preventDefault();
    const data: IAuditEventSearchCriteria = {};

    const startDate = dateRange?.startDate;
    const endDate = dateRange?.endDate;

    if (startDate) {
      data["startTime"] = convertLogDateToUTC(startDate);
    }

    if (endDate && !isNaN(endDate.getTime())) {
      data["endTime"] = convertLogDateToUTC(endDate);
    }

    for (const item of event.target) {
      if (item.value) {
        data[item.name as keyof IAuditEventSearchCriteria] = item.value;
      }
    }

    if (selectedAuditTypes.length !== 0) {
      data["eventType"] = selectedAuditTypes.map((auditType: any) => {
        return auditType.value;
      });
    }

    if (selectedEmail) {
      data["userEmail"] = selectedEmail.value;
    }
    await getAuditLogs(data);
    setIsDirty(true);
  }

  async function getAuditLogs(parameters: IAuditEventSearchCriteria) {
    if (!isAdminUser) {
      return;
    }
    const { status, payload } = await api.callAPI(
      RequestType.Post,
      "/log/audit/list",
      "Failed to get list of audit logs due to the following error:",
      parameters,
    );

    if (status === 200) {
      setAuditLogs(payload.events);
      setAuditLogsIncomplete(payload.incomplete ?? false);
    }
  }

  function convertToKeyValuePair(
    enumList: typeof QueryType | typeof AuditEventType,
  ): { value: string; label: string }[] {
    const options: { value: string; label: string }[] = [];

    for (const key in enumList) {
      if (isNaN(Number(key))) {
        const value = enumList[key];
        options.push({ value: value, label: key });
      }
    }

    return options;
  }

  // Prepare Audit Log Table
  const auditLogColumns = [
    {
      dataField: "timestamp",
      text: "Timestamp",
      sort: false,
    },
    {
      dataField: "eventType",
      text: "Event Type",
      sort: false,
    },
    {
      dataField: "user",
      text: "User",
      sort: false,
      hidden: isOEM,
    },
    {
      dataField: "targetName",
      text: "Target Resource",
      sort: false,
    },
  ];

  const auditLogData = auditLogs.map((auditLog: any, index: any) => {
    return {
      index: index,
      timestamp: formatUTCDateTime(auditLog.timestamp) + " UTC",
      eventType:
        AuditEventType[auditLog.eventType]
          ?.match(/[A-Z][a-z]+|[0-9]+|[A-Z]{3}/g)
          ?.join(" ") ?? "",
      user: auditLog.userName ?? "CData Support",
      targetName:
        EntityType[auditLog.targetType] +
        ": " +
        (auditLog.targetName ? auditLog.targetName : auditLog.targetId),
      detail: auditLog.detail,
      targetId: auditLog.targetId,
      relatedId: auditLog.relatedId,
      relatedName: auditLog.relatedName
        ? auditLog.relatedName
        : auditLog.relatedId,
      relatedType: auditLog.relatedType
        ? EntityType[auditLog.relatedType]
            .match(/[A-Z][a-z]+|[0-9]+/g)
            ?.join(" ") ?? ""
        : null,
      ipAddress: auditLog.ipAddress,
    };
  });
  // Automatically generate a type from the auditLogData array.
  type IAuditLogData = (typeof auditLogData)[number];

  const expandAuditRow = {
    className: "log-table-expanded-row",
    renderer: (row: IAuditLogData) => (
      <div className="mt-2 mb-n2">
        <Row className="justify-content-start gap-5">
          {row.ipAddress && (
            <Col>
              <p className="log-table-expanded-detail">
                <b className={row.relatedName ? "mb-2" : ""}>IP Address</b>
                <br />
                <span>{row.ipAddress}</span>
              </p>
            </Col>
          )}
          {row.detail && (
            <Col>
              <p className="log-table-expanded-detail">
                <b className={row.relatedName ? "mb-2" : ""}>Details</b>
                <br />
                <span>{row.detail}</span>
              </p>
            </Col>
          )}
          {row.relatedName && (
            <Col>
              <p className="log-table-expanded-detail">
                <b className="mb-2">Related Resource</b>
                <br />
                <span>
                  {row.relatedType} &quot;{row.relatedName}&quot;
                </span>
              </p>
            </Col>
          )}
        </Row>
        {!row.detail && !row.relatedName ? (
          <Row>
            <Col>
              <p className="mb-3">
                No additional detail available for this audit log.
              </p>
            </Col>
          </Row>
        ) : null}
      </div>
    ),
    showExpandColumn: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }: any) =>
      isAnyExpands ? (
        <i className="fa-regular fa-circle-minus cursor-pointer"></i>
      ) : (
        <i className="fa-regular fa-circle-plus cursor-pointer"></i>
      ),
    expandColumnRenderer: ({ expanded }: any) =>
      expanded ? (
        <i className="fa-regular fa-circle-minus cursor-pointer"></i>
      ) : (
        <i className="fa-regular fa-circle-plus cursor-pointer"></i>
      ),
  };

  const auditLogTable = (
    <Card>
      <CardBody>
        <h5 className="card-title">Audit Log</h5>
        <BootstrapTable
          bootstrap4
          id="auditLogTable"
          classes="log-table"
          bordered={false}
          keyField="index"
          columns={auditLogColumns}
          data={auditLogData}
          expandRow={expandAuditRow}
          pagination={paginationFactory({
            sizePerPage: 10,
            sizePerPageList: [5, 10, 25, 50],
          })}
        />
      </CardBody>
    </Card>
  );

  function toggleFilterOptions() {
    setFilterOptionsOpen(!filterOptionsOpen);
  }

  function resetFilters() {
    clearDateRange();
    clearDateRangeSelection();
    setSelectedAuditTypes([]);
    setSelectedEmail(null);
  }

  const ItemRenderer = ({
    option,
    onClick,
    disabled,
  }: {
    option: Option;
    onClick: () => void;
    disabled: boolean;
  }) => (
    <div
      onClick={() => handleItemClick(option, onClick, disabled)}
      className={`multi-select-item ${disabled ? "disabled" : ""}`}
    >
      {option.label}
    </div>
  );

  const renderClearButton = (
    selected: Option[],
    clearFunction: () => void,
    label: string,
  ) => {
    if (selected.length === 0) return null;

    return (
      <CDataButton
        className="quick-clear-button"
        buttonType={ButtonType.Borderless}
        aria-label={`Clear ${label}`}
        onClick={(event) => {
          clearFunction();
          event.stopPropagation();
        }}
      >
        <i className="fa fa-solid fa-circle-xmark" />
      </CDataButton>
    );
  };

  const renderBadge = (selected: Option[]) => {
    if (selected.length === 0) return null;

    return (
      <Badge className="multi-drop-down-badge badge" color="">
        {selected.length}
      </Badge>
    );
  };

  const isDateRangeSelected = dateRange?.startDate ? 1 : 0;

  function customValueRenderer(
    selected: Option[],
    _options: Option[],
    label: string,
    clearFunction: () => void,
  ) {
    if (selected.length === 0 && label !== "Date Range") {
      return <span className="multi-drop-down-placeholder">{label}</span>;
    }

    if (label === "Date Range" && isDateRangeSelected === 0) {
      return <span className="multi-drop-down-placeholder">{label}</span>;
    }

    return (
      <>
        <span>{label}</span>
        <div className="multi-drop-down-button">
          {renderBadge(selected)}
          {renderClearButton(selected, clearFunction, label)}
        </div>
      </>
    );
  }

  const clearAuditEventTypes = () => {
    setSelectedAuditTypes([]);
  };

  const clearUserEmail = () => {
    setSelectedEmail(null);
  };

  if (loading) {
    return (
      <Container fluid className="p-0">
        <Loader />
      </Container>
    );
  }
  return (
    <>
      <Card>
        <CardBody>
          {!flags.audit_log_filters.enabled ? (
            <div>
              <Row className="w-100">
                <Col className="me-n2 expand-filter-box">
                  <a
                    id="collapseFilter"
                    href="#collapseFilter"
                    className="text-decoration-none"
                    onClick={toggleFilterOptions}
                  >
                    <h4 className="card-title mb-0">
                      {filterOptionsOpen === false ? (
                        <i className="fa-regular fa-circle-plus"></i>
                      ) : (
                        <i className="fa-regular fa-circle-minus"></i>
                      )}
                    </h4>
                  </a>
                </Col>
                <CDataDateRangePicker
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                />
                <Col className="filter-logs-button">
                  <Button
                    id="filterButton"
                    form="auditFilterForm"
                    color="primary"
                    type="submit"
                    data-testid="button-filter-search"
                  >
                    <i className="fa fa-search align-middle me-2 no-pointer-event" />
                    Search
                  </Button>
                </Col>
              </Row>
              <UncontrolledCollapse
                toggler="#collapseFilter"
                defaultOpen={false}
                className="mt-4"
              >
                <Form id="auditFilterForm" onSubmit={submitAuditFilters}>
                  <Row>
                    <Col className="flexbox-container">
                      <label>Audit Event Type:</label>
                      <MultiSelect
                        options={auditTypeOptions}
                        value={selectedAuditTypes}
                        onChange={setSelectedAuditTypes}
                        labelledBy="Select"
                      />
                    </Col>
                  </Row>
                </Form>
              </UncontrolledCollapse>
            </div>
          ) : (
            <Row className="pages-logs-Table">
              <Form id="auditFilterForm" onSubmit={submitAuditFilters}>
                <Row className="mb-4">
                  <Col className="def">
                    <span ref={myRef} className="align-date-range">
                      <MultiSelect
                        onMenuToggle={() => setAnchorEl(myRef?.current)}
                        options={dateRangeOptions}
                        value={selectedDateRange ? [selectedDateRange] : []}
                        onChange={handleDateRangeSelectChange}
                        labelledBy="Date Range"
                        hasSelectAll={false}
                        disableSearch
                        closeOnChangedValue={true}
                        valueRenderer={(selected, options) =>
                          customValueRenderer(
                            selected,
                            options,
                            "Date Range",
                            clearDateRange,
                          )
                        }
                        ClearSelectedIcon
                        ItemRenderer={ItemRenderer}
                      />
                      {selectedDateRange?.label === "Custom" && (
                        <CDataLogsDateRangePicker
                          anchorEl={anchorEl}
                          dateRange={dateRange}
                          setDateRange={setDateRange}
                        />
                      )}
                    </span>
                  </Col>
                  <Col className="def flexbox-container dropdown-container">
                    <MultiSelect
                      options={auditTypeOptions}
                      value={selectedAuditTypes}
                      onChange={setSelectedAuditTypes}
                      labelledBy="Select Event Type"
                      valueRenderer={(selected, options) =>
                        customValueRenderer(
                          selected,
                          options,
                          "Event Type",
                          clearAuditEventTypes,
                        )
                      }
                      ClearSelectedIcon
                    />
                  </Col>
                  {!isQueryUser && (
                    <Col className="def flexbox-container dropdown-container x">
                      <MultiSelect
                        options={emailOptions}
                        value={selectedEmail ? [selectedEmail] : []}
                        onChange={handleEmailSelectChange}
                        labelledBy="Select User"
                        hasSelectAll={false}
                        disableSearch
                        closeOnChangedValue={true}
                        valueRenderer={(selected, options) =>
                          customValueRenderer(
                            selected,
                            options,
                            "User",
                            clearUserEmail,
                          )
                        }
                        ClearSelectedIcon
                        ItemRenderer={({
                          option,
                          onClick,
                          disabled,
                        }: {
                          option: Option;
                          onClick: () => void;
                          disabled: boolean;
                        }) => (
                          <div
                            onClick={() => {
                              if (!disabled) {
                                onClick();
                              }
                            }}
                            className={`multi-select-item ${disabled ? "disabled" : ""}`}
                          >
                            {option.label}
                          </div>
                        )}
                      />
                    </Col>
                  )}
                  <Col className="custom-col-button">
                    <Button
                      id="filterButton"
                      form="auditFilterForm"
                      color="primary"
                      type="submit"
                      disabled={!isDirty && filterButtonDisabled}
                    >
                      <i className="fa fa-check align-middle me-2 no-pointer-event" />
                      Apply
                    </Button>
                    <Button
                      id="filterButton"
                      form="auditFilterForm"
                      className="tertiary-button btn-secondary clear-all-button"
                      onClick={() => resetFilters()}
                      aria-label="delete license"
                      disabled={filterButtonDisabled}
                    >
                      <i className="fa-regular fa-xmark"></i>&nbsp;&nbsp;Clear
                      all
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Row>
          )}
        </CardBody>
      </Card>
      <Row>
        <Col>{auditLogTable}</Col>
      </Row>
      <sub
        style={{
          display: auditLogsIncomplete ? "block" : "none",
        }}
      >
        There are older results that were not returned; please narrow your
        search or adjust your time range if you wish to view them.
      </sub>
    </>
  );
};

export default AuditTable;
